import React, { useState } from "react";
// import { Element } from 'react-scroll';
// import VisibilitySensor from "react-visibility-sensor";

import Header from '../components/Header/Header';
// import HeroTheLegend from '../components/HeroTheLegend/HeroTheLegend';
// import Reserve from '../components/Reserve/Reserve';
import StoreLocator from '../components/StoreLocator/StoreLocator';
import Subscribe from '../components/Subscribe/Subscribe';
import Instagram from '../components/Instagram/Instagram';
import Footer from '../components/Footer/Footer';

const IndexPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = isVisible => setIsVisible(isVisible);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
    >
      <Header productIsActive={isVisible} />
      <StoreLocator style={{marginTop: '67px'}}/>
      <Subscribe />
      <Instagram />
      <Footer />
    </div>
  );
};

export default IndexPage;
